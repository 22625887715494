import React, {useEffect} from 'react'
import { useTranslation } from 'react-i18next';
import { useParams, useNavigate } from 'react-router-dom';
import axios from 'axios';
import Navigation from './Navigation';
import {getCurrency, MaterialName, getMaterialPrice} from './Utils/RecipeTools'

import PaginationList from './Utils/PaginationList';
import erkconfig from "./Configuration"

const ReplaceRecipes = ({ children }) => {

    const { t } = useTranslation();

    const token = localStorage.getItem('t');

    const [recipes, setRecipes] = React.useState(null);

    const [addMaterialDialogVisible, setAddMaterialDialogVisible] = React.useState(false);
    const [filterStr, setFilterStr] = React.useState("");
    const [filteredMaterials, setFilteredMaterials] = React.useState([]);

    const [selectedMaterial, setSelectedMaterial] = React.useState(null);
    const [selectedReplaceMaterial, setSelectedReplaceMaterial] = React.useState(null);
    const [materialInRecipeCount, setMaterialInRecipeCount] = React.useState(0);

    const [allDone, setAllDone] = React.useState(false);
    const [replaceError, setReplaceError] = React.useState(false);
    

    const handleFilterChange = (event) => {
        let value = event.target.value;
        setFilterStr(value);
    }

    const addMaterial = (material) => {
        setAllDone(false)
        setReplaceError(false);

        if (selectedMaterial) {
            // this is for second select
            setSelectedReplaceMaterial(material)

        } else {
            // This is for first select
            setSelectedMaterial(material)
            setRecipes(null);
            let params = {
                token: token,
                materialid: material.id
            };
            axios.post(erkconfig.BASEURL + '/apps/erk_recipes?action=checkforreplace', params)
            .then(function (response) {
                if (response.data.success) {
                    setRecipes(response.data.recipes)
                    setMaterialInRecipeCount(response.data.recipes.length)
                }
            })
            .catch(function(error) {
            });


        }
        
        setAddMaterialDialogVisible(false)
    }

    const clearRecipes = (event) => {
        event.preventDefault()
        setRecipes(null)
        setMaterialInRecipeCount(0)
        setSelectedMaterial(null)

        setReplaceError(false);
        setAllDone(false)
    }

    const startReplace = () => {
        let params = {
            token: token,
            originalmaterialid: selectedMaterial.id,
            replacematerialid: selectedReplaceMaterial.id
        };
        axios.post(erkconfig.BASEURL + '/apps/erk_recipes?action=replacematerial', params)
        .then(function (response) {
            if (response.data.success) {
                setRecipes(null)
                setMaterialInRecipeCount(0)
                setSelectedMaterial(null)
                setSelectedReplaceMaterial(null)
                setAllDone(true)
            } else {
                setReplaceError(true);
            }
        })
        .catch(function(error) {
            setReplaceError(true);
        });

    }
    

    const loadMaterials = () => {
        setFilteredMaterials(null)
        let params = {};
        if (filterStr) {
            params.filter = filterStr
        }
        axios.post(erkconfig.BASEURL + '/apps/erk_materials?action=search', params)
        .then(function (response) {
            if (response.data.success) {
                setFilteredMaterials(response.data.materials)
            }
        })
        .catch(function(error) {
        });
    }

    useEffect(() => {
		if (!filteredMaterials) {
            loadMaterials();
		}
	}, [])

    useEffect(() => {
        loadMaterials();
	}, [filterStr])


	return (
        <>

            <div className={addMaterialDialogVisible ? "modal fade show d-block" : "modal fade"} id="addMaterialDialog" tabIndex="-1" aria-labelledby="addMaterialDialog" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable modal-xl">
                    <div className="modal-content">
                        <div className="modal-header flex-column pb-0">
                            <div className="d-flex w-100">
                                <div className="input-group mb-2 pe-2">
                                    <input ref={input => input && input.focus()} type="text" className="form-control" placeholder={t('recipe.search-placeholder')} aria-label={t('recipe.search-placeholder')} value={filterStr} onChange={handleFilterChange}/>
                                    <span className="input-group-text" id="basic-addon2"><i className="bi bi-search"></i></span>
                                </div>
                                <button type="button" className="btn-close mb-2" aria-label="Close" onClick={() => setAddMaterialDialogVisible(false)}></button>
                            </div>

                            <div className="w-100">
                                <div className="row table-header small no-border px-2">
                                    <div className="col-10">{t('recipe.materials-name')}</div>
                                    <div className="col-2 justify-content-center">{t('recipe.materials-eko')}</div>
                                </div>
                            </div>

                        </div>
                        <div className="modal-body pt-0 px-4" style={{minHeight: "512px", maxHeight: "512px"}}>
                            {filteredMaterials &&
                            <>
                                {filteredMaterials.map((material) =>
                                <div key={material.id} className="row table-row small cursor-pointer" onClick={() => addMaterial(material)}>
                                    <div className="col-10 br-d justify-content-start"><MaterialName {...material} /></div>
                                    <div className="col-2 br-d justify-content-center">{material.EKO ? <i className="bi bi-check-circle-fill ekomark eko"></i> : <i className="bi bi-check-circle-fill ekomark"></i>}</div>
                                </div>
                                )}
                            </>
                            }
                        </div>
                    </div>
                </div>
            </div>

            {(addMaterialDialogVisible) &&
                <div className="modal-backdrop fade show"></div>
            } 

            <div className="content-box green">
                <div className="row align-items-end">
                    <div className="col-md-12">
                        <h3>{t('recipes.replacetitle')}</h3>
                        <p>{t('recipes.replacedesc')}</p>
                    </div>
                </div>
            </div>

            <Navigation />

            <div className="content-box">
                <h4>{t('recipes.selectmaterialtitle')}</h4>
                <p>{t('recipes.selectmaterial')}</p>

                {(filteredMaterials && !selectedMaterial) &&
                    <>
                        <div className="mb-3">
                            <button className="btn btn-secondary" onClick={() => setAddMaterialDialogVisible(true)}>{t('recipes.selectmaterialplaceholder')}</button>
                        </div>
                    </>
                }

                {(selectedMaterial && selectedMaterial.NAME) &&
                    <>
                    <p><strong>{selectedMaterial.NAME}</strong> {t('recipes.selectedmaterialinfo', {count: materialInRecipeCount})}</p>

                    {recipes &&
                    <>
                        {recipes.map((recipe) => (
                            <div key={recipe.id} className="replace-recipe-row">{recipe.NAME}</div>
                        ))}
                    </>
                    }
                    <a href="#" className="d-block my-1" onClick={clearRecipes}>{t('recipes.clearselectedmaterial')}</a>

                    {materialInRecipeCount>0 &&
                    <>
                        <p className="mt-4">{t('recipes.selectreplacematerial')}</p>
                        {(filteredMaterials && !selectedReplaceMaterial) &&
                            <>
                                <div className="mb-3">
                                    <button className="btn btn-secondary" onClick={() => setAddMaterialDialogVisible(true)}>{t('recipes.selectmaterialplaceholder')}</button>
                                </div>
                            </>
                        }

                        {(selectedReplaceMaterial && selectedReplaceMaterial.NAME) &&
                        <>
                            <p>{t('recipes.selectedreplacedmaterialinfo1')} <strong><MaterialName {...selectedMaterial} /></strong> {t('recipes.selectedreplacedmaterialinfo2')} <strong><MaterialName {...selectedReplaceMaterial} /></strong></p>
                            <div className="mb-3">
                                <button className="btn btn-secondary" onClick={startReplace}>{t('recipes.replacematerials')}</button>
                            </div>
                        </>
                        }

                    </>
                    }

                    </>
                }       

                {allDone &&
                    <div className="alert alert-success mt-3" role="alert">{t('recipes.materialreplaced')}</div>
                }    

                {replaceError &&
                    <div className="alert alert-danger mt-3" role="alert">{t('recipes.materialreplacederror')}</div>
                }  



            </div>
        </>
    )
}
export default ReplaceRecipes;
