import React, {useEffect} from 'react'
import axios from 'axios';
import erkconfig from "../Configuration"
import { rectSortingStrategy } from '@dnd-kit/sortable';

let originalmaterials = null;
const loadOriginalmaterials = () => {
    let params = {
    };
    axios.post(erkconfig.BASEURL + '/apps/erk_materials?action=getall', params)
    .then(function (response) {
        if (response.data.success) {
            originalmaterials = response.data.originalmaterials;
        }
    })
    .catch(function(error) {
        console.error(error)
    });
}
loadOriginalmaterials();


let usermaterials = null;
export const loadUsermaterials = () => {
    const token = localStorage.getItem('t');
    let params = {
        token: token,
    };
    axios.post(erkconfig.BASEURL + '/apps/erk_materials?action=getusermaterials', params)
    .then(function (response) {
        if (response.data.success) {
            usermaterials = response.data.usermaterials;
        }
    })
    .catch(function(error) {
        console.error(error)
    });
}
loadUsermaterials();


// get gstring objects
export const getParameterByName = (name) => {
    name = name.replace(/[\[]/, "\\[").replace(/[\]]/, "\\]");
    var regex = new RegExp("[\\?&]" + name + "=([^&#]*)"),
        results = regex.exec(location.search);
    return results == null ? "" : decodeURIComponent(results[1].replace(/\+/g, " "));
}


export const checkNum = (num) => {
    if (typeof num === 'string' || num instanceof String) {
        num = num.replace(',', '.');
    }

    num = isNaN(num) || num === '' || num === null || num == Infinity ? 0 : num;
    return parseFloat(num);
}

export const roundNum = (num, digits) => {
    if (digits==null || digits==undefined) {
        digits = 4;
    }
    if (typeof num === 'string' || num instanceof String) {
        num = num.replace(',', '.');
        num = Number(num);
    }
    let roundedString = num.toFixed(digits);
    return Number(roundedString)
}

export const getRealMaterial = (id) => {
    let returnMaterial = null;
    if (originalmaterials && id) {
        for (let i = 0; i<originalmaterials.length; i++) {
            if (id==originalmaterials[i].id) {
                returnMaterial = originalmaterials[i];
                break;
            }
        }
    }
    return returnMaterial;
}

export const MaterialName = (item) => {
    let newname = item.NAME;
    if (item.SUBNAME) {
        newname = newname + ", " + item.SUBNAME
    }
    if (item.EKO) {
        newname = newname + " (luomu)"
    }
    return newname;
}

export const getCurrency = (number) => {
    if (!number) {
        return "0.00"
    }
	return "" + number.toFixed(2).replace('.', ',')
}

export const getNumber = (number, decimals) => {
    if (decimals==undefined) {
        decimals = 2;
    }
    if (!number) {
        number = 0.00;
    }
	return "" + number.toFixed(decimals).replace('.', ',')
}

export const getRecipeEkoPros = (recipe, forprice) => {

    let portions = checkNum(recipe.PORTIONS);
    if (!portions) {
        portions = 1;
    }

    let ekopros = 0;
    let totalweight = 0.00;
    let ekoweight = 0.00;

    let totalprice = 0.00;
    let totalekoprice = 0.00;

    recipe.MATERIALS.forEach((material) => {
        let realmaterial = getRealMaterial(material.MATERIALID);
        if (realmaterial) {
            let calcInEko = true
            if (realmaterial.DONT_INCLUDE_AS_LUOMU) {
                calcInEko = false;
            }

            let rowweight = 0.00;
            let rowprice = 0.00;
            let rowpricePros = 0;
            if (material.WEIGHT) {
                rowweight = checkNum(material.WEIGHT);
                if (material.LOSSLESS) {
                    rowweight = checkNum(material.LOSSLESS);
                }
                if (calcInEko) {
                    totalweight = totalweight + rowweight;
                    if (realmaterial.EKO) {
                        ekoweight = ekoweight + rowweight;
                    }
                }
            }
            if (rowweight>0.00) {
                // check if is own price
                let price = realmaterial.PRICE;
                if (material.PRICE && (material.PRICE!==realmaterial.PRICE)) {
                    price = checkNum(material.PRICE);
                }

                if (forprice) {
                    rowprice = checkNum(price)
                } else {
                    rowprice = (rowweight / 1000) * checkNum(price)
                }
                rowpricePros = ((rowweight*100) / 1000);
                let tempPrice = ((rowprice/100) * rowpricePros);
                totalprice = totalprice + tempPrice;
                if (realmaterial.EKO) {
                    totalekoprice = totalekoprice + tempPrice;
                }
                //console.log(material.NAME + ":" + rowprice + " | " + rowpricePros + " | " + totalprice + " | " + totalekoprice)
            }
        }
    });

    if (forprice) {
        // calc for price
        if (totalweight>0) {
            ekopros = (totalekoprice * 100) / totalprice;
            //console.log("Total: (" + totalekoprice + " * 100) / " + totalprice + " = " + ekopros)
        }
    } else {
        // calc for kilogram
        if (totalweight>0) {
            ekopros = (ekoweight * 100) / totalweight;
        }
    }

    return "" + Math.round(ekopros).toFixed(0);
}

export const getMaterialPrice = (material, realmaterialprice) => {
    let price = 0.00;

    if (material) {

        //console.log(material.NAME)
        if (realmaterialprice) {
            price = realmaterialprice;
            //console.log(' - realmaterialprice = ' + realmaterialprice)
        } else {
            let materialid = material.MATERIALID;
            if (!materialid) {
                materialid = material.id;
            }
            let realmaterial = getRealMaterial(materialid);
            if (realmaterial) {
                price = realmaterial.PRICE;
                //console.log(' - realmaterial = ' + realmaterial.NAME)
            }
            //console.log(' - price = ' + price)
        }

        let idfield = "id";
        if (material.MATERIALID) {
            idfield = "MATERIALID";
        }

        if (material[idfield] && usermaterials) {
            for (let i = 0; i<usermaterials.length; i++) {
                if (material[idfield]==usermaterials[i].MATERIALID) {
                    price = usermaterials[i].PRICE;
                    //console.log(' - userprice = ' + price)
                    break;
                }
            }
        }
    }

    return price;
}

export const getRecipePortionPrice = (recipe) => {
    let portionprice = 0.00;
    let totalPriceValue = 0.00;

    let portions = checkNum(recipe.PORTIONS);
    if (!portions) {
        portions = 1;
    }

    recipe.MATERIALS.forEach((material) => {

        let realmaterial = getRealMaterial(material.MATERIALID);

        if (realmaterial) {
            let rowweight = 0.00;
            let rowprice = 0.00;

            if (material.WEIGHT) {
                rowweight = checkNum(material.WEIGHT);
                if (material.LOSSLESS) {
                    //rowweight = checkNum(material.LOSSLESS);
                }
            }

            let price = realmaterial.PRICE;
            let materialprice = getMaterialPrice(material, price);
            if (materialprice && (materialprice!==realmaterial.PRICE)) {
                price = checkNum(materialprice);
            }

            if (rowweight>0.00) {
                rowprice = (rowweight / 1000) * price
                totalPriceValue = totalPriceValue + rowprice;
                //console.log(material.NAME + ": (" + rowweight + " / 1000) * " + price + " = " + rowprice);
                
            }
        } else {
            //console.log("Realmaterial not found: " + material.MATERIALID );
        }
        
    });

    portionprice = totalPriceValue / portions;
    //console.log(totalPriceValue + " / " + portions + " = " + portionprice)

    return "" + portionprice.toFixed(2).replace('.', ',')
}

export const getWeights = (recipe, lpc) => {
    let weights = {
        total: 0,
        eko: 0,
        lossless: 0,
        losslesspercent: 0
    };
    if (!lpc) {
        lpc = 0;
    }

    recipe.MATERIALS.forEach((material) => {
        let realmaterial = getRealMaterial(material.MATERIALID);
        if (realmaterial) {
            let calcInEko = true
            if (realmaterial.DONT_INCLUDE_AS_LUOMU) {
                calcInEko = false;
            }

            let rowweight = 0.00;
            let ekoweight = 0.00;
            if (material.WEIGHT) {
                rowweight = checkNum(material.WEIGHT);
                if (material.LOSSLESS) {
                    rowweight = checkNum(material.LOSSLESS);
                }
                if (realmaterial.EKO) {
                    ekoweight = rowweight;
                }
            }
            if (calcInEko) {
                // add eko weight for materials that doesn't marked as DONT_INCLUDE_AS_LUOMU
                weights.eko = weights.eko + ekoweight;  
            }
            // add total weight to all materials
            weights.total = weights.total + rowweight;
        }
    });
    let losslessweight = weights.total / 100 * lpc;
    weights.lossless = weights.total - losslessweight;
    weights.losslesspercent = 100 * (weights.total - weights.lossless) / weights.total

    return weights
}

export const getPrices = (recipe) => {
    let prices = {
        total: 0.00,
        eko: 0.00
    };

    recipe.MATERIALS.forEach((material) => {
        let realmaterial = getRealMaterial(material.MATERIALID);
        if (realmaterial) {
            let calcInEko = true
            if (realmaterial.DONT_INCLUDE_AS_LUOMU) {
                calcInEko = false;
            }

            let rowprice = 0.00;
            let ekoprice = 0.00;


            // check if is own price
            let price = realmaterial.PRICE;
            let materialprice = getMaterialPrice(material, price);
            if (materialprice && (materialprice!==realmaterial.PRICE)) {
                price = checkNum(materialprice);
            }

            if (calcInEko) {
                if (price) {
                    rowprice = checkNum(price);
                    if (realmaterial.EKO) {
                        ekoprice = price;
                    }
                }
                prices.total = prices.total + rowprice;
                prices.eko = prices.eko + ekoprice;
            }

        }
    });

    return prices
}

export const getPortionWeight = (recipe, lpc) => {
    let portionweight = 1;
    let totalWeight = 0;
    let portions = recipe.PORTIONS;
    if (!portions) {
        portions = 1;
    }
    if (!lpc) {
        if (recipe.COOKINGLOSSLESS) {
            lpc = checkNum(recipe.COOKINGLOSSLESS)
        } else {
            lpc = 1;
        }
    }

    if (portions > 0) {
        recipe.MATERIALS.forEach((material) => {
            let rowweight = 0.00;
            if (material.WEIGHT) {
                rowweight = checkNum(material.WEIGHT);
                if (material.LOSSLESS) {
                    rowweight = checkNum(material.LOSSLESS);
                }
            }
            totalWeight = totalWeight + rowweight;

        });
        if (lpc!==1) {
            portionweight = (totalWeight / portions) * (1 - (lpc / 100));
        } else {
            portionweight = (totalWeight / portions);
        }
        //console.log(totalWeight + " / " + portions + " = " + portionweight)
    }

    return "" + portionweight.toFixed(0)
}

export const getNutritiondata = (recipe, portionweight, forPortion) => {

    //console.log("getNutritiondata: " + portionweight + " " + forPortion)
    if (recipe.NAME=="Pinaatti-raejuustonappi") {
        //console.log(recipe)
        //console.log(" - getNutritiondata: " + portionweight + " " + forPortion)
    }
    
    portionweight = checkNum(portionweight);

    let portions = checkNum(recipe.PORTIONS);
    if (!portions) {
        portions = 1;
    }

    let nd = {
        kcal: 0,
        kj: 0,
        rasva: 0,
        rasvapros: 0,
        rasvatyyd: 0,
        rasvatyydpros: 0,
        hiilihydraatit: 0,
        hiilihydraatitpros: 0,
        hiilihydraatitsok: 0,
        hiilihydraatitsokpros: 0,
        proteiini: 0,
        proteiinipros: 0,
        ravintokuitu: 0,
        natrium: 0,
        suola: 0,
        avit: 0,
        dvit: 0,
        cvit: 0,
        b1: 0,
        folaatti: 0
    }

    // calc total weights
    let totalweight = 0.00;
    recipe.MATERIALS.forEach((material) => {
        let weight = checkNum(material.WEIGHT);
        if (material.LOSSLESS) {
            weight = checkNum(material.LOSSLESS);
        }
        if (weight) {
            totalweight = totalweight + weight;
            
            if (material.kcal && material.kcal!=='null') {
                let r = (weight / 100) * checkNum(material.kcal);
                if (!isNaN(r)) {nd.kcal = roundNum(nd.kcal + r);}
            }
            if (material.kj && material.kj!=='null') {
                let r = (weight / 100) * checkNum(material.kj);
                if (!isNaN(r)) {nd.kj = nd.kj + r;}
            }
            if (material.rasva && material.rasva!=='null') {
                let r = (weight / 100) * checkNum(material.rasva);
                if (!isNaN(r)) {nd.rasva = nd.rasva + r;}
            }
            if (material.rasvatyyd && material.rasvatyyd!=='null') {
                let r = (weight / 100) * checkNum(material.rasvatyyd);
                if (!isNaN(r)) {nd.rasvatyyd = nd.rasvatyyd + r;}
            }
            if (material.hiilihydraatit && material.hiilihydraatit!=='null') {
                let r = (weight / 100) * checkNum(material.hiilihydraatit);
                if (!isNaN(r)) {nd.hiilihydraatit = nd.hiilihydraatit + r;}
            }
            if (material.hiilihydraatitsok && material.hiilihydraatitsok!=='null') {
                let r = (weight / 100) * checkNum(material.hiilihydraatitsok);
                if (!isNaN(r)) {nd.hiilihydraatitsok = nd.hiilihydraatitsok + r;}
            }
            if (material.proteiini && material.proteiini!=='null') {
                let r = (weight / 100) * checkNum(material.proteiini);
                if (!isNaN(r)) {nd.proteiini = nd.proteiini + r;}
            }
            if (material.ravintokuitu && material.ravintokuitu!=='null') {
                let r = (weight / 100) * checkNum(material.ravintokuitu);
                if (!isNaN(r)) {nd.ravintokuitu = nd.ravintokuitu + r;}
            }
            if (material.suola && material.suola!=='null') {
                let r = (weight / 100) * checkNum(material.suola);
                if (!isNaN(r)) {nd.suola = nd.suola + r;}

            } else if (material.natrium && material.natrium!=='null') {
                let r = (weight / 100) * checkNum(material.natrium) / 1000;
                if (!isNaN(r)) {
                    nd.natrium = nd.natrium + r;
                    nd.suola = nd.suola + (r * 2.548);
                }
            }
            
            if (material.avit && material.avit!=='null') {
                let r = (weight / 100) * checkNum(material.avit);
                if (!isNaN(r)) {nd.avit = nd.avit + r;}
            }
            if (material.dvit && material.dvit!=='null') {
                let r = (weight / 100) * checkNum(material.dvit);
                if (!isNaN(r)) {nd.dvit = nd.dvit + r;}
            }
            if (material.cvit && material.cvit!=='null') {
                let r = (weight / 100) * checkNum(material.cvit);
                if (!isNaN(r)) {nd.cvit = nd.cvit + r;}
            }
            if (material.b1 && material.b1!=='null') {
                let r = (weight / 100) * checkNum(material.b1);
                if (!isNaN(r)) {nd.b1 = nd.b1 + r;}
            }
            if (material.folaatti && material.folaatti!=='null') {
                let r = (weight / 100) * checkNum(material.folaatti);
                if (!isNaN(r)) {nd.folaatti = nd.folaatti + r;}
            }
        }
    });
    //console.log(totalweight)

    if (forPortion) {
        // calc per portion
        let rp = (((nd.rasva / portions) * 9) / (nd.kcal / portions)) * 100;
        if (!isNaN(rp)) { nd.rasvapros = rp; }
    
        rp = ((nd.rasvatyyd / portions) / (nd.rasva / portions)) * 100;
        if (!isNaN(rp)) { nd.rasvatyydpros = rp; }
    
        rp = (((nd.hiilihydraatit / portions) * 4) / (nd.kcal / portions)) * 100;
        if (!isNaN(rp)) { nd.hiilihydraatitpros = rp; }

        //rp = (nd.hiilihydraatitsok / portions) * 100 / portionweight;
        rp = ((nd.hiilihydraatitsok / portions) / (nd.hiilihydraatit / portions)) * 100;
        if (!isNaN(rp)) { nd.hiilihydraatitsokpros = rp; }
    
        rp = (((nd.proteiini / portions) * 4) / (nd.kcal / portions)) * 100;
        if (!isNaN(rp)) { nd.proteiinipros = rp; }

        nd.kcal = (nd.kcal / portions);
        nd.kj = (nd.kj / portions);
        nd.rasva = (nd.rasva / portions);
        nd.rasvatyyd = (nd.rasvatyyd / portions);
        nd.hiilihydraatit = (nd.hiilihydraatit / portions);
        nd.hiilihydraatitsok = (nd.hiilihydraatitsok / portions);
        nd.proteiini = (nd.proteiini / portions);
        nd.ravintokuitu = (nd.ravintokuitu / portions);
        nd.natrium = ((nd.natrium) / portions); // Ei jakoa
        nd.suola = (nd.suola / portions); // Ei jakoa
        nd.avit = (nd.avit / portions);
        nd.dvit = (nd.dvit / portions);
        nd.cvit = (nd.cvit / portions);
        nd.b1 = (nd.b1 / portions);
        nd.folaatti = (nd.folaatti / portions);

    } else {

        // calc per 100g
        let kcalSata = (nd.kcal / totalweight) * 100;
        let rasvaSata = (nd.rasva / totalweight) * 100;
        let rasvatyydSata = (nd.rasvatyyd / totalweight) * 100;
        let hiilihydraattiSata = (nd.hiilihydraatit / totalweight) * 100;
        let hiilihydraattisokSata = (nd.hiilihydraatitsok / totalweight) * 100;
        let proteiiniSata = (nd.proteiini / totalweight) * 100;
        
        let rp = ((rasvaSata * 9) / kcalSata) * 100;
        if (!isNaN(rp)) { nd.rasvapros = rp; }
        
        rp = (rasvatyydSata / rasvaSata) * 100;
        if (!isNaN(rp)) { nd.rasvatyydpros = rp; }
    
        rp = ((hiilihydraattiSata * 4) / kcalSata) * 100;
        if (!isNaN(rp)) { nd.hiilihydraatitpros = rp; }
    
        rp = (hiilihydraattisokSata / hiilihydraattiSata) * 100;
        if (!isNaN(rp)) { nd.hiilihydraatitsokpros = rp; }
    
        rp = ((proteiiniSata * 4) / kcalSata) * 100;
        if (!isNaN(rp)) { nd.proteiinipros = rp; }
    
        // calc weight by portion size
        //nd.kcal = (nd.kcal / totalweight) * 100;
        let ss = (nd.kcal / totalweight) * 100;
        if (!isNaN(ss)) {nd.kcal = ss}
        nd.kj = (nd.kj / totalweight) * 100;
        nd.rasva = (nd.rasva / totalweight) * 100;
        nd.rasvatyyd = (nd.rasvatyyd / totalweight) * 100;
        nd.hiilihydraatit = (nd.hiilihydraatit / totalweight) * 100;
        nd.hiilihydraatitsok = (nd.hiilihydraatitsok / totalweight) * 100;
        nd.proteiini = (nd.proteiini / totalweight) * 100;
        nd.ravintokuitu = (nd.ravintokuitu / totalweight) * 100;
        nd.natrium = ((nd.natrium)  / totalweight);
        nd.suola = (nd.suola / totalweight) * 100;
        nd.avit = (nd.avit / totalweight) * 100;
        nd.dvit = (nd.dvit / totalweight) * 100;
        nd.cvit = (nd.cvit / totalweight) * 100;
        nd.b1 = (nd.b1 / totalweight) * 100;
        nd.folaatti = (nd.folaatti / totalweight) * 100;
    }

    nd.totalweight = totalweight;
    if (recipe.NAME=="Pinaatti-raejuustonappi") {
        //console.log(nd)
    }

    return nd
}

